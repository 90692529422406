@font-face {
  font-family: 'Smart';
  src: url('Smart-MediumItalic.woff2') format('woff2'),
      url('Smart-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Smart';
  src: url('Smart-Regular.woff2') format('woff2'),
      url('Smart-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Smart';
  src: url('Smart-BoldItalic.woff2') format('woff2'),
      url('Smart-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Smart';
  src: url('Smart-Bold.woff2') format('woff2'),
      url('Smart-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Smart';
  src: url('Smart-LightItalic.woff2') format('woff2'),
      url('Smart-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Smart';
  src: url('Smart-Black.woff2') format('woff2'),
      url('Smart-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Smart';
  src: url('Smart-Medium.woff2') format('woff2'),
      url('Smart-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Smart';
  src: url('Smart-Light.woff2') format('woff2'),
      url('Smart-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Smart';
  src: url('Smart-RegularItalic.woff2') format('woff2'),
      url('Smart-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Smart';
  src: url('Smart-BlackItalic.woff2') format('woff2'),
      url('Smart-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
