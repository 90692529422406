@import "./assets/fonts/AAA-fonts.css";

html, body {
    height: 100%;
    min-height: 100%;
}
body {
    margin: 0;
    font-family: 'Smart', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
}

#root {
    min-height: 100%;
    position: relative;
    padding-bottom: 335px;
    box-sizing: border-box;
}

iframe#launcher {
  bottom: 5px!important;
}

@media screen and (min-width: 961px) {
    #root {
        padding-bottom: 316px;
    }
}

form {
  width: 100%;
}

.tooltip {
  width: 200px;
  padding: 20px !important;
  opacity: 1 !important;
}

input {
  font-family: 'Smart', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.carousel .slide { background: none !important; }

.react-mark-down, .react-mark-down p {
  display: block;
}

.paragraph p{
  font-size: 16px;
  margin-top: 2em;
}

.react-mark-down, .paragraph a {
  color: #003087;
  text-decoration: none;
}

.react-tabs__tab-list {
  border-bottom: none !important;
  list-style-type: none;
}

.react-tabs__tab--selected {
  border-bottom: none !important;
  border-radius: 0px;
  bottom: 10px !important;
  border: 0 !important
}  

.react-tabs__tab {
  width: 33%;
  padding: 4px !important;
}

.react-tabs__tab-product-chart {
  width: 33%;
  padding: 4px !important;
}

.react-tabs__tab:nth-child(1), .react-tabs__tab:nth-child(1)::after {
  background: #58B2E7;
}

.react-tabs__tab:nth-child(2), .react-tabs__tab:nth-child(2)::after {
  background: #0972CE;
}

.react-tabs__tab:last-child, .react-tabs__tab:last-child::after {
  background: #003087 !important;
}

.react-tabs__tab:first-child:nth-last-child(2):focus::after {
  background: #0972CE;
}

.react-tabs__tab:first-child:nth-last-child(3):focus::after {
  background: #58B2E7;
}

.react-tabs__tab:nth-child(n+2):nth-last-child(n-2):focus::after {
  background: #0972CE;
}
.react-tabs__tab:focus::after {
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}
.react-tabs__tab:focus {
  box-shadow: none !important;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}    
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 4px;
  outline: none;
  background-clip: content-box;
  border: 2px solid #C4C4C4;
  border-radius: 50%;
}

#rich-text a {
  color: #003087;
  text-decoration: none;
  font-weight: 500;
  display: inline;
}

input[type="radio"]:checked {
  background-color: #003087;
  border: 2px solid #003087; 
}

.masonry-grid {
  display: flex;
  width: 100%;
}
 
.masonry-grid_column > div {
  width: auto;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.toggle-ButtonStyle:focus{
  outline: unset  !important;
}

.carousel-description p{
  font-size:24px;
  color: #252525;
  max-width: 82%;
  margin-top: 1rem;
}
.carousel-description-mobile p {
  font-size: 11px;
  width: 188px;
}
@media screen and (max-width: 1280px) {
  .hero-section-image{
    transform: translate(-10%, 0%);
  }
}
@media screen and (max-width: 960px) {
  .paragraph p {
    font-size: 14px;
  }
}
.pagination {
  display: inline-block;
  padding-left: unset;
  padding-right: 15px;
}

.pagination li {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 25px;
}

.pagination li:first-child {
  padding-left: unset;
}

.pagination li:hover {
  cursor: pointer;
}

.pagination .previous, .pagination .next {
  font-size: 30px;
  vertical-align: middle;
}

.active {
  border: 1px solid #B0B0B0;
  border-radius: 3px;
  padding: 10px;
}

.disabled-pagination-buttons {
  color: #DCDCDC;
}
.margin-0 p{
  margin: 0;
}
#ae_launcher {
  bottom: 17px !important;
  margin-right: 210px !important;
}
@media screen and (max-width: 960px) {
  #ae_launcher {
    bottom: 17px !important;
    margin-right: 50px !important;
  }
}
